<template>
  <div>
    <loading v-if="isLoading" />
    <error v-else-if="isError" />
    <content-not-view
      v-else-if="!subPermission.update || !subPermission.store"
    />
    <b-card v-else :title="$t('g.basicInformations')">
      <!-- form -->
      <validation-observer ref="form">
        <b-form>
          <b-row>
            <!-- Name -->
            <b-col md="4">
              <b-form-group :label="$t('g.name')" label-for="name">
                <validation-provider
                  #default="{ errors }"
                  name="buy request type name"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.name"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('g.placeholder.enterTheNameHere')"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Name -->

            <!-- language update -->
            <b-col md="4" v-if="isEdit">
              <b-form-group :label="$t('g.language')" label-for="Language">
                <validation-provider
                  #default="{ errors }"
                  name="Language"
                  rules="required"
                >
                  <select class="form-control" v-model="form_data.lang">
                    <option selected value>
                      {{ $t("g.Please_Select_Language") }}
                    </option>
                    <option
                      v-for="(lang, index) in lang_options"
                      :key="index"
                      :value="lang.value"
                    >
                      {{ lang.text }}
                    </option>
                  </select>
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Submit Button -->
            <b-col cols="12">
              <b-button
                :variant="isEdit ? 'success' : 'primary'"
                type="submit"
                @click.prevent="validationForm"
              >
                {{ isEdit ? $t("g.edit") : $t("g.submit") }}
              </b-button>
            </b-col>
            <!-- ** Submit Button -->
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BCard,
  BFormFile,
  BSpinner,
  BFormTextarea,
  BFormCheckbox,
  BFormSelect,
} from "bootstrap-vue";
import { required } from "@validations";
import Loading from "@/views/components/logic/loading.vue";
import Error from "@/views/components/logic/error.vue";
import ContentNotView from "@/views/components/logic/contentNotView.vue";
import store from "@/store/index";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    BFormFile,
    BSpinner,
    Loading,
    Error,
    BFormTextarea,
    BFormCheckbox,
    ContentNotView,
    BFormSelect,
  },
  data() {
    return {
      form_data: {
        name: null,
        lang: null,
      },
      lang_options: [
        { value: "ar", text: this.$t("g.Arabic") },
        { value: "en", text: this.$t("g.English") },
      ],
      buyRequestTypeId: null,
      isEdit: false,
      isLoading: true,
      isError: null,
      pageName: null,
      routePageName: null,
      subPermission: {},
    };
  },

  watch: {
    $route(to, from) {
      if (to.name === "AddBuyRequestType") {
        this.isEdit = false;
        this.clearForm();
      } else if (to.name === "EditBuyRequestType") {
        this.isEdit = true;
      }
    },
  },

  beforeMount() {
    if (this.$route.params.id) {
      this.buyRequestTypeId = this.$route.params.id;
      this.getBuyRequestType(this.buyRequestTypeId);
      this.isEdit = true;
    }
    setTimeout(() => {
      this.isLoading = false;
      this.isError = false;
      store.dispatch("GET_PERMISSION", "buyRequestTypes");
      this.subPermission = store.state.permissions.sub;
    }, 1000);
  },

  methods: {
    clearForm() {
      for (const i in this.form_data) {
        this.form_data[i] = null;
      }
    },
    getBuyRequestType(id) {
      this.$http
        .get(`admin/buyRequestTypes/${id}`, {
          headers: {
            "x-locale": localStorage.getItem("lang"),
          },
        })
        .then((res) => {
          this.form_data.name = res.data.data.name;
          this.form_data.lang = res.data.data.lang;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
          this.isError = true;
        });
    },
    //  Sweet Toast Function config
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },
    // Send the modified data
    submitEdit(id) {
      this.$refs.form.validate().then((success) => {
        if (success) {
          // append data to FormData object before sending to backend
          let formData = new FormData();

          for (let item in this.form_data) {
            formData.append(item, this.form_data[item.toString()]);
          }

          // call Backend
          this.$http
            .post(`admin/buyRequestTypes/${id}`, formData, {
              params: { _method: "put" },
            })
            .then((res) => {
              this.makeToast(
                "success",
                this.$t("g.send.successBody"),
                this.$t("g.send.successTitle")
              );
              setTimeout(() => {
                this.$router.push({ name: "ListOfBuyRequestTypes" });
              }, 500);
            })
            .catch((err) => {
              for (const key in err.response.data.errors) {
                this.makeToast(
                  "danger",
                  err.response.data.errors[key][0],
                  this.$t("g.send.errorTitle")
                );
              }
              console.log(err);
            });
        } else {
          this.makeToast(
            "warning",
            this.$t("g.send.invalidInputBody"),
            this.$t("g.send.invalidInput")
          );
        }
      });
    },
    //  Submit new lab
    submitNewAdditon() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          // append data to FormData object before sending to backend
          let formData = new FormData();
          for (let item in this.form_data) {
            formData.append(item, this.form_data[item.toString()]);
          }
          this.$http
            .post("admin/buyRequestTypes", formData)
            .then((res) => {
              this.makeToast(
                "success",
                this.$t("g.send.successBody"),
                this.$t("g.send.successTitle")
              );
              setTimeout(() => {
                this.$router.push({ name: "ListOfBuyRequestTypes" });
              }, 1000);
            })
            .catch((err) => {
              for (const key in err.response.data.errors) {
                this.makeToast(
                  "danger",
                  err.response.data.errors[key][0],
                  this.$t("g.send.errorTitle")
                );
              }
              console.log(err);
            });
        } else {
          this.makeToast(
            "warning",
            this.$t("g.send.invalidInputBody"),
            this.$t("g.send.invalidInput")
          );
        }
      });
    },
    //  Submit Function
    validationForm() {
      if (this.isEdit) {
        this.submitEdit(this.buyRequestTypeId);
      } else {
        delete this.form_data.lang;
        this.submitNewAdditon();
      }
    },
  },
};
</script>
<style lang="scss">
.loading-div {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.label-box {
  label,
  div {
    display: inline-block !important;
  }

  label {
    margin: 0 5px;
  }
}
</style>
